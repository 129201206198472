import * as Yup from 'yup';
import { useState } from 'react';
import { ReactSession } from 'react-client-session';

import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Link, Stack, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();


  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);

    let user = {
      email: event.target.elements.email.value,
      password: event.target.elements.password.value
    };

    axios.post(`${process.env.REACT_APP_URLDB}/login`, user).then((response) => {
      if (response.data.error.code === 1) {
        setpwdCorrect(response.data.error.message);
      }
      else {
        setpwdCorrect("");
        ReactSession.setStoreType("localStorage");
        ReactSession.set("myuser", response.data.payload);
        user = response.data.payload;
        navigate('/dashboard/app', { replace: true });
        window.location.reload();
      }

      setIsLoading(false);
    }).catch(e => {
      setIsLoading(false);
    });
  };

  const [isLoading, setIsLoading] = useState(false);

  const [pwdCorrect, setpwdCorrect] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      navigate('/dashboard', { replace: true });
    },
  });

  const { errors, touched, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>
        <Typography variant="subtitle1" sx={{ mb: 5, color: 'red' }}>
          {pwdCorrect}
        </Typography>



        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>

          <Link component={RouterLink} variant="subtitle2" to="/password/forget" underline="hover">
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isLoading}>
          Login
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
