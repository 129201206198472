import { Grid } from '@mui/material';

import CardApiary from "./CardApiary";


export default function ApiaryList({ apiaries, setApiary }) {
    return (
        apiaries.map(ap => {
            return (
                <Grid key={ap.id_apiary} item xs={12} sm={6} md={3} mr={10}>
                    <CardApiary apiary={ap} setApiary={setApiary} />
                </Grid>
            )
        })

    );

}