import { Grid } from "@mui/material";
import dateFormat from "dateformat";
import { Container } from "react-bootstrap";
import { AppTemperatureDegrees } from "../sections/@dashboard/app";



export default function GraphTemperature({ details }) {

    return (
        details.length !== 0 ?
            <Grid item xs={12} md={6} lg={8}>
                <AppTemperatureDegrees
                    title="Temperature Graph"
                    chartLabels={details.map((det) =>
                        dateFormat(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(det.date.slice(0, 19)), "mm/dd/yyyy"), "mm/dd/yyyy"))
                    }
                    chartData={[
                        {
                            name: 'Temp',
                            type: 'line',
                            fill: 'solid',
                            data: details.map((det) => det.htemp),
                        }, {
                            name: 'envTemp',
                            type: 'line',
                            fill: 'solid',
                            data: details.map((det) => det.etemp),
                        },
                        {
                            name: 'weight',
                            type: 'bar',
                            fill: 'solid',
                            data: details.map((det) => (det.weight / 1000).toFixed(2)),
                        },
                    ]}
                />
            </Grid> : <Container />



    );

}