import { ReactSession } from 'react-client-session';

// ----------------------------------------------------------------------
ReactSession.setStoreType("localStorage");

let user = ReactSession.get("myuser");

if (user === undefined) {
  user = {
    firstName: "",
    lastName: "",
    type_user: -1,
  }
}

const account = {
  firstName: `${user.firstName} ${user.lastName}`,
  email: user.email,
  photoURL: '/static/mock-images/avatars/avatar_default.jpg',
  type_user: user.type_user,
};

export default account;
