import { Card, IconButton, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios';
import HouseSidingIcon from '@mui/icons-material/HouseSiding';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import {  useState } from 'react';
import { ReactSession } from 'react-client-session';
import { Container } from 'react-bootstrap';


export default function CardApiary({ apiary, setApiary }) {
    ReactSession.setStoreType("localStorage");
    const user = ReactSession.get("myuser");


    const [isEdit, setisEdit] = useState(false);
    const [name, setName] = useState(apiary.name);
    // const [countHives, setCountHives] = useState(0);



    const editApiary = () => {
        setisEdit(!isEdit);

        if (isEdit) {
            axios.post(`${process.env.REACT_APP_URLDB}/editapiary`, { 'id_apiary': apiary.id_apiary, 'name': name }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response.data.error.code === 1) {
                    console.log("errore");
                }

                apiary.name = name;
                setApiary(oldArray => oldArray.map(el => {
                    return (el.id_apiary.toString() === apiary.id_apiary.toString() ? apiary : el);
                }));
            });


        }
    }

    // useEffect(() => {
    //     axios.post(`${process.env.REACT_APP_URLDB}/listhives`, { 'id_apiary': apiary.id_apiary }, {
    //         headers: {
    //             'Authorization': `Bearer ${user.token}`
    //         }
    //     }).then((response) => {
    //         if (response.data.payload.length > 0) {
    //             setCountHives(response.data.payload.length);
    //         }
    //     });
    // }, []);

    return (
        <>
            {user.type_user === 0 ? (<Container />):
            (<IconButton aria-label="edit" color="secondary" sx={{ alignItems: "center" }} onClick={editApiary}>
                {isEdit ? <DoneIcon /> : <EditIcon />}
            </IconButton>)}

            <Link to={isEdit ? "" : "../Hives"}
                state={apiary}
                style={{ textDecoration: 'none' }}

            >
                <Card
                    sx={{
                        py: 5,
                        mb: 10,
                        boxShadow: 0,
                        textAlign: 'center',
                        color: "#000",
                        bgcolor: "#ffff66",
                        width: {
                            sx: 1.0,
                            sm: 250,
                            md: 350,
                        },
                    }}
                >

                    <HouseSidingIcon />

                    <Typography variant="h3" sx={{ opacity: 0.72, '&:hover': { textDecoration: 'underline' } }} contentEditable={isEdit ? "true" : "false"} suppressContentEditableWarning="true" onInput={(e) => setName(e.currentTarget.textContent)} >
                        {apiary.name}
                    </Typography>
                    {/* <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>Hives : {countHives}</Typography> */}
                </Card>
            </Link>
        </>


    );

}