import { Stack } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import Logo from "../components/Logo";
import Page from "../components/Page";

export default function PasswordForget() {

    const [show, setShow] = useState(false);


    const handleSubmit = (event) => {
        event.preventDefault();

        const email = event.target.elements.email.value;

        axios.post(`${process.env.REACT_APP_URLDB}/password/forgetpassword`, { 'email': email }).then((response) => {
            if (response.data.error.code === 0) {
                setShow(true);
            }
            else {
                console.log("error");
            }
        });


    }

    return (

        <Page title="Dashboard">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <div className="col-md-6 login-form">
                    <div className="login_form_in">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Logo />
                        </div>
                        <h1 className="auth_title text-left">Password Reset</h1>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={4}>
                                <div className="alert alert-success bg-soft-primary border-0" role="alert" hidden={!show}>
                                    We have successfully sent the recovery link email.
                                    Check your email inbox.
                                </div>
                                <div className="alert alert-warning bg-soft-primary border-0" role="alert" hidden={show}>
                                    Enter your email address and we'll send you an email with instructions to reset your password.
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" name="email" placeholder="Email Address" />
                                </div>
                                <div className="form-group">
                                    <button type="submit" className="btn btn-primary btn-lg btn-block">Reset Password</button>
                                </div>
                            </Stack>
                        </form>
                    </div>
                </div>
            </div>
        </Page >)
}