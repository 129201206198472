import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

export default function TabPanel(props){
    const { children, value, index, ...other } = props;
      
    return (
        <Typography component="div" role="tabpanel" hidden={value !== index} id={`action-tabpanel-${index}`} aria-labelledby={`action-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </Typography>
    );
}