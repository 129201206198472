import { Stack, Typography } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import Logo from "../components/Logo";
import Page from "../components/Page";

export default function PasswordRecovery() {
    const [show, setShow] = useState(false);
    const [unmatchPassword, setUnmatchPassword] = useState(false);

    const token = new URLSearchParams(window.location.search).get("token");


    const changePassword = (event) => {

        event.preventDefault();

        const email = event.target.elements.email.value;
        const password = event.target.elements.password.value;
        const confirmPassword = event.target.elements.confirmpassword.value;

        if (password.match(confirmPassword)) {
            setUnmatchPassword(false);

            axios.post(`${process.env.REACT_APP_URLDB}/password/reset`, { 'email': email, 'password': password, 'token': token }).then((response) => {
                if (response.data.error.code === 0) {
                    setShow(true);
                }
                else {
                    console.log("error");
                }
            });

        }
        else {
            setUnmatchPassword(true);
        }


    }

    return (

        <Page title="Dashboard">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh',
                }}
            >
                <div className="col-md-6 login-form">
                    <div className="login_form_in">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Logo />
                        </div>
                        <h1 className="auth_title text-left">Password Reset</h1>
                        <form onSubmit={changePassword} >
                            <div className="form-group">
                                <Stack spacing={3}>
                                    <input type="text" className="form-control" name="token" hidden />
                                    <input type="email" className="form-control" name="email" placeholder="Email" />
                                    <input type="password" className="form-control" name="password" placeholder="Password" />
                                    <input type="password" className="form-control" name="confirmpassword" placeholder="Confirm Password" />
                                    <Typography variant="subtitle1" sx={{ mb: 5, color: 'red' }} hidden={!unmatchPassword}>
                                        Password doesn't match
                                    </Typography>
                                </Stack>
                            </div>
                            <br />
                            <div className="form-group">
                                <button type="submit" className="btn btn-primary btn-lg btn-block">Change password</button>
                            </div>
                            <br />
                            <div className="alert alert-success bg-soft-primary border-0" role="alert" hidden={!show}>
                                Password changed successfully! Go to <a href="../login">Login</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Page >);
}