import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom'
import { CircularProgress, Container, Divider, Grid, IconButton, Stack, TextField, Typography, Tab, Tabs } from '@mui/material';

import { ReactSession } from 'react-client-session';
import dateFormat from 'dateformat';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';


// icons
import AddLinkIcon from '@mui/icons-material/AddLink';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ReactDatePicker from 'react-datepicker';
import AppFlow from '../sections/@dashboard/app/AppFlow';
import AppWeight from '../sections/@dashboard/app/AppWeight';

import loader from '../assets/preloader.gif';

import GraphTemperature from '../components/GraphTemperature';
import GraphHumidity from '../components/GraphHumidity';
import TabPanel from '../components/TabPanel';
import InterventoList from '../components/InterventoList';
import DevicesList from '../components/DevicesList';
import Page from '../components/Page';
import HiveList from '../components/HiveList';







export default function Hives() {
    const location = useLocation();
    const apiary = location.state;
    const idResearcher = location.state.id_user

    const [hives, setHives] = useState([]);
    const [filteredHives, setFilteredHives] = useState(hives);
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadData, setIsLoadData] = useState(false);

    const [availableDevices, setAvailableDevices] = useState([]); // tutti i device collegati al mio user
    const [occupedDevices, setOccupedDevices] = useState([]);   // tutti i device che sono collegati a un arnia
    const [isSeeAddDevice, setisSeeAddDevice] = useState(false);
    const [currentDevice, setCurrentDevice] = useState([{ "id_device": 0, "name": "" }]);
    const [currentHive, setCurrentHive] = useState([{ "id_hive": 0, "name": "" }]);
    const [devices, setDevices] = useState([]); // tutti i device collegati all'arnia selezionata
    const [interventi, setInterventi] = useState([]);
    const [filteredDetails, setFilteredDetails] = useState([]);
    const [hiveDevices, setHiveDevices] = useState([]); // nomi degli alveari e id del device abbinato
    const [beeFilesElab, setBeeFilesElab] = useState([]); // beesense collegato per tabella bee files già elaborati
    const [uniqueDateBeeFiles, setuniqueDateBeeFiles] = useState([]); // beesense collegato per tabella bee files già elaborati
    const [uniqueDateBeeFilesFiltered, setuniqueDateBeeFilesFiltered] = useState(null); // beesense collegato per tabella bee files già elaborati
    const theme = useTheme();
    const [tabValue, setTabValue] = React.useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [dateFilter, setDateFilter] = useState();
    const [endDate, setEndDate] = useState(new Date());
    const [flowDetail, setFlowDetail] = useState([]);
    const [weightDetail, setWeightDetail] = useState([]);



    ReactSession.setStoreType("localStorage");
    const user = ReactSession.get("myuser");
    if (typeof user === "undefined") {
        window.location.href = "/login";
    }

    const isSuperResearcher = user.type_user === 0;


    useEffect(() => {
        axios.post(`${process.env.REACT_APP_URLDB}/getcategory`, {}, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setCategory(response.data.payload);
        });

        axios.post(`${process.env.REACT_APP_URLDB}/getsubcategory`, {}, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            setSubcategory(response.data.payload);
        });


        const id = toast.loading(`Getting your hives from ${apiary.name}...`);

        axios.post(`${process.env.REACT_APP_URLDB}/listhives`, { 'id_apiary': apiary.id_apiary }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            if (response.data.error.code === 1) {
                toast.update(id, { render: "Error!", type: "error", isLoading: false });
            }
            else {
                setHives(response.data.payload);
                setFilteredHives(response.data.payload);
                toast.update(id, {
                    render: "Done!", type: "success", isLoading: false, autoClose: 2000,
                });
            }

        }).catch((e) => toast.update(id, { render: `Error with connection! ${e.toString()}`, type: "error", isLoading: false, autoClose: 2000 }));



    }, []);



    const showLink = (ad) => {
        // if (isSuperResearcher) return <Container />;
        return <IconButton aria-label="link device" color="secondary" onClick={() => LinkedNewDevice(ad)}>
            <AddIcon />
        </IconButton>;
    };

    useEffect(() => {

        axios.post(`${process.env.REACT_APP_URLDB}/listdevices`, { 'id_user': isSuperResearcher ? idResearcher : user.id_user, 'date_now': dateFormat(new Date(), "yyyy/mm/dd") }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            if (response.data.error.code === 1) {
                console.log("Errore");
            }
            else {
                setAvailableDevices(response.data.payload.availableDevices);
                // setOccupedDevices(response.data.payload.occupedDevices);
                setHiveDevices(response.data.payload.hiveName);

            }
        });

    }, []);







    function LinkedNewDevice(device) {
        const id = toast.loading(`Linking ${device.Nickname} to ${currentHive.name}`);
        axios.post(`${process.env.REACT_APP_URLDB}/linkdevice`, { 'id_hive': currentHive.id_hive, 'id_device': device.id_device, 'date_start': dateFormat(new Date(), "yyyy/mm/dd"), 'date_end': dateFormat(new Date(null, null, null), "yyyy/mm/dd") }, {
            headers: {
                'Authorization': `Bearer ${user.token}`
            }
        }).then((response) => {
            if (response.data.error.code === 1) {
                toast.update(id, { render: "Error!", type: "error", isLoading: false });
            }
            else if(response.data.error.code === 2){
                toast.update(id, { render: "The device is still in this hive", type: "warning", isLoading: false, autoClose: 5000, });

            }
            else {

                setDevices(oldArray => [...oldArray, device]);
                const newDeviceHolder = { id_device: device.id_device, name: currentHive.name };
                setHiveDevices(oldArray => [...oldArray, newDeviceHolder]);

                // setAvailableDevices(oldArray =>oldArray.filter((d) => d.id_device !== device.id_device));
                device.id_hive = response.data.payload.id_hive;
                // setOccupedDevices(oldArray => [...oldArray, device]);
                toast.update(id, {
                    render: "Done!", type: "success", isLoading: false, autoClose: 2000,
                });

            }

        });
    }

    const hiddenFileInput = React.useRef(null);

    const handleFile = event => {
        // const id = toast.loading(`Uploading file ${event.target.files[0].name} in Hive: ${currentHive.name}`);

        // const file = event.target.files;

        /*
       const reader = new FileReader();
       reader.readAsDataURL(file);
    
    
       reader.onload = () => {
           // console.log(event.target.result);
           axios.post(`${process.env.REACT_APP_URLDB}/upload-file`, { 'id_hive': currentHive.id_hive, 'file': file, 'filename': file.name }, {
               headers: {
                   'Content-Type': 'multipart/form-data'
               }
           }).then((response) => {
    
               toast.update(id, {
                   render: "Uploaded!", type: "success", isLoading: false, autoClose: 2000,
               });
               console.log(response.data);
               console.log("INVIATO");
    
           });
       }
    
    */


    }

    /*
    
      "sensorTime"
      "sensorName"
      "sensorEnvHum"
      "sensorTemp"
      "sensorBattery"
      "sensorEnvTemp"
      "sensorWeight"
      "sensorApiaryName"
      "sensorLat"
      "sensorLong"
  
      */

    const updateGraph = (dates) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);

        if (start != null && end != null) {
            setIsLoadData(true);

            start.setHours(0, 0, 0);
            end.setHours(23, 59, 59);

            axios.post(`${process.env.REACT_APP_URLDB}/gethistory`, { 'MAC': currentDevice.MAC, 'date_start': start, 'date_end': end }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response.data.error.code === 1) {
                    console.log("errore");
                }

                setFilteredDetails(response.data.payload);
                setIsLoadData(false);

            });


        }


    };

    const updateGraphFlow = (dates) => {
        const [start, end] = dates;

        setStartDate(start);
        setEndDate(end);

        if (start != null && end != null) {
            setIsLoadData(true);

            start.setHours(0, 0, 0);
            end.setHours(23, 59, 59);

            axios.post(`${process.env.REACT_APP_URLDB}/gethistoryFlow`, { 'MAC': currentDevice.MAC, 'date_start': start, 'date_end': end }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response.data.error.code === 1) {
                    console.log("errore");
                }
                setFlowDetail(response.data.payload.filter(el => el.flow !== null));
                setWeightDetail(response.data.payload.filter(el => el.weight !== null && el.weight !== 0));
                console.log(response.data.payload);
                // console.log(response.data.payload.filter(el => el.flow !== null));
                setIsLoadData(false);

            });


        }


    };

    const filterHives = event => {
        const filter = event.target.value;

        setFilteredHives(filter === "" ? hives : hives.filter(h => h.name.toLowerCase().includes(filter.toLowerCase())));
    }

    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleChangeIndexTab = (index) => {
        setTabValue(index);
    };


    const onChangeDateBeeFiles = (date) => {
        if (uniqueDateBeeFilesFiltered == null) {
            setuniqueDateBeeFilesFiltered(uniqueDateBeeFiles);
        }
        if (date === null) {
            setuniqueDateBeeFiles(uniqueDateBeeFilesFiltered);
        }
        else {
            setuniqueDateBeeFiles(Array.from(uniqueDateBeeFilesFiltered).filter(bf => new Date(bf).getTime() === new Date(date).getTime()));
        }

        setDateFilter(date);
    }



    return (
        <Page title="Hives">
            <ToastContainer />
            <Container maxWidth="l">
                <Typography variant="h5" sx={{ mb: 5, color: "#606060" }} >
                    Your hives from apiary <b><i>{apiary.name}</i></b>
                </Typography>
                <Row >
                    <Col>
                        <MyModal idApiary={apiary.id_apiary} setHives={setHives} />
                    </Col>
                    <Col>
                        <span>
                            <TextField id="standard-basic" name="filterHive" label="Search Hive" variant="standard" onChange={filterHives} sx={{ mr: 50 }} />
                        </span>
                    </Col>
                </Row>

            </Container>

            <br />


            <Container maxWidth="l">
                {hives.length === 0 ? <Container>There are no hives here, create one now.</Container> :

                    <Grid container mt={10} mb={10}>
                        <HiveList setDates={setuniqueDateBeeFiles} setBeeFilesElab={setBeeFilesElab} hives={filteredHives} currentDevice={currentDevice} setCurrentDevice={setCurrentDevice} currentHive={currentHive} setCurrentHive={setCurrentHive} setHives={setHives} setDevices={setDevices} setInterventi={setInterventi} setIsLoading={setIsLoading} />
                    </Grid>

                }

            </Container>

            <div ><h2 id="hivedetail" style={{ textAlign: "center" }}>{currentHive.name}</h2></div>
            <div hidden>
                <input type="file" name="file[]" ref={hiddenFileInput} onChange={handleFile} accept=".bee" multiple />
            </div>
            <Divider variant="middle" />


            {isLoading && (
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress
                        size={68}
                        sx={{
                            color: '#FFCC99',
                            mt: 2,
                            mr: 2
                        }}
                    />
                </div>)}

            {currentHive.id_hive !== undefined && !isLoading ? (
                <div> Devices connected to this hive:
                    <IconButton aria-label="open detail" color="secondary" onClick={() => {
                        setisSeeAddDevice(!isSeeAddDevice);
                    }}>
                        {isSeeAddDevice ? <ArrowBackIcon /> : <AddLinkIcon />}
                    </IconButton>
                </div>
            ) : <Container />
            }




            {
                isSeeAddDevice ?
                    <Table responsive="sm">
                        <thead>
                            <tr>
                                <th>Device Name</th>
                                {/* <th>MAC</th> */}
                                <th>add to Hive</th>
                            </tr>
                        </thead>
                        <tbody>

                            {availableDevices.map((ad) => (
                                <><tr><td>{ad.Nickname}</td> {/* <td>{ad.MAC}</td> */}<td>
                                    {occupedDevices.some(e => e.id_device === ad.id_device) ?
                                        <IconButton aria-label="off" color="secondary" size="small" disabled>
                                            <div>({hiveDevices.filter(d => d.id_device === ad.id_device).map(dev => dev.name)}) </div>
                                            <BlockIcon />
                                        </IconButton>
                                        :
                                        showLink(ad)}
                                </td></tr></>

                            )
                            )}

                        </tbody>
                    </Table>

                    : <Container />
            }

            {(devices.length) !== 0 && !isLoading ? <DevicesList currentHive={currentHive} devices={devices} setOccupedDevices={setOccupedDevices} setDevices={setDevices} setCurrentDevice={setCurrentDevice} currentDevice={currentDevice} setHiveDevices={setHiveDevices} setBeeFilesElab={setBeeFilesElab} setDates={setuniqueDateBeeFiles} /> : <Container />}



            { /* TEMPERATURE TABLE */
                currentDevice.id_device !== undefined && currentDevice.type === '1' && !isLoading ?
                    <>

                        <Row>
                            <Col> <p>Select Range</p><ReactDatePicker
                                selected={startDate}
                                onChange={updateGraph}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                            /></Col>
                        </Row>

                        <Tabs value={tabValue} onChange={handleChangeTab} indicatorColor="primary" >
                            <Tab label="Temperature Graph" />
                            <Tab label="Humidity Graph" />
                        </Tabs>
                        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={tabValue} onChangeIndex={handleChangeIndexTab}>
                            <TabPanel value={tabValue} index={0} dir={theme.direction}>
                                {isLoadData ?
                                    <img src={loader} alt="loading..." />
                                    :
                                    <GraphTemperature details={filteredDetails} />}
                            </TabPanel>
                            <TabPanel value={tabValue} index={1} dir={theme.direction}>
                                <GraphHumidity details={filteredDetails} />
                            </TabPanel>
                        </SwipeableViews>
                    </>
                    : <Container />
            }

            {/* FLOW TABLE */
                currentDevice.id_device !== undefined && currentDevice.type === '3' && !isLoading ?
                    <>

                        <Row>
                            <Col> <p>Select Range</p><ReactDatePicker
                                selected={startDate}
                                onChange={updateGraphFlow}
                                startDate={startDate}
                                endDate={endDate}
                                selectsRange
                            /></Col>
                        </Row>

                        <Tabs value={tabValue} onChange={handleChangeTab} indicatorColor="primary" >
                            <Tab label="Flight Graph" />
                            <Tab label="Weight Graph" />
                        </Tabs>
                        <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={tabValue} onChangeIndex={handleChangeIndexTab}>
                            <TabPanel value={tabValue} index={0} dir={theme.direction}>
                                {isLoadData ?
                                    <img src={loader} alt="loading..." />
                                    : <AppFlow
                                        title="Flight Graph"
                                        chartLabels={flowDetail.map((det) =>
                                            dateFormat(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(det.date.slice(0, 19)), "mm/dd/yyyy"), "mm/dd/yyyy"))
                                        }
                                        chartData={[
                                            {
                                                name: 'Flight',
                                                type: 'line',
                                                fill: 'solid',
                                                data: flowDetail.map((det) => det.flow),
                                            },
                                        ]}
                                    />
                                }
                            </TabPanel>
                            <TabPanel value={tabValue} index={1} dir={theme.direction}>
                                {isLoadData ?
                                    <img src={loader} alt="loading..." />
                                    : <AppWeight
                                        title="Weight Graph"
                                        chartLabels={weightDetail.map((det) =>
                                            dateFormat(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(det.date.slice(0, 19)), "mm/dd/yyyy"), "mm/dd/yyyy"))
                                        }
                                        chartData={[
                                            {
                                                name: 'Flow',
                                                type: 'bar',
                                                fill: 'solid',
                                                data: weightDetail.map((det) => (det.weight / 1000).toFixed(2)),
                                            },
                                        ]}
                                    />}
                            </TabPanel>
                        </SwipeableViews>
                    </>
                    : <Container />
            }


            <br />

            {currentHive.id_hive !== undefined && !isLoading ? <InterventoList interventi={interventi} setInterventi={setInterventi} hive={currentHive} category={category} subcategory={subcategory} /> : <Container />}

            <Modal onHide={() => setCurrentDevice([])} show={currentDevice.id_device !== undefined && currentDevice.type === '2'} size="xl" centered style={{ position: 'fixed', zIndex: '10000' }}>
                <Modal.Header closeButton>
                    <Modal.Title>Available data of {currentDevice.Serial}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ border: '0.5px solid grey', borderRadius: '20px', marginTop: 20 }}>
                        <Container>
                            <Row>
                                <div className="d-flex">
                                    <ReactDatePicker
                                        value={dateFilter}
                                        placeholderText="Filter by date"
                                        selected={dateFilter}
                                        onChange={onChangeDateBeeFiles}
                                    />
                                    {/* <IconButton onClick={eraseDateFilter}>
                                    <Backspace />
                                </IconButton> */}
                                </div>
                            </Row>
                            {Array.from(uniqueDateBeeFiles).length === 1 ? <h4 style={{ textAlign: "center", color: 'grey' }}>No files uploaded yet for this device</h4> : Array.from(uniqueDateBeeFiles).map((file, i) => (
                                <Row style={{ marginBottom: 15 }} key={`${i}_bee`}>
                                    <Col>{`${file}`}</Col>
                                    <Col><ul key={`${i}_bee_table`}>{beeFilesElab.filter(bf => bf.date === file).map(time => <li key={`${time.time_start}_${time.time_end}`}>{time.time_start} - {time.time_end}</li>)}</ul></Col>
                                    <Divider color="black" />
                                </Row>
                            )
                            )}
                        </Container>
                    </div>
                </Modal.Body>

            </Modal>

        </Page >

    );




    function MyModal({ idApiary, setHives }) {

        /*
                let imgSrc;
        
                const onFileChange = event => {
                    imgSrc = event.target.files[0];
                };


                if (imgSrc !== undefined) {
                    const reader = new FileReader();
                reader.readAsDataURL(imgSrc);
                reader.onload = function () {
                    hive.image = reader.result;
                    };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                    };
        
                }
                else{
                    console.log("UNDEF");
        
                }
                */

        const handleSubmit = (event) => {
            event.preventDefault();
            const id = toast.loading(`Adding hive to database...`);


            const hive = {
                id_apiary: event.target.elements.id_apiary.value,
                name: event.target.elements.name.value,
                description: event.target.elements.description.value,
                image: " "
            };


            axios.post(`${process.env.REACT_APP_URLDB}/addhive`, hive, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response.data.error.code === 1) {
                    toast.update(id, {
                        render: "Error while adding hive.", type: "error", isLoading: false, autoClose: 2000,
                    });
                }
                else {
                    setHives(oldArray => [...oldArray, response.data.payload]);
                    setFilteredHives(oldArray => [...oldArray, response.data.payload]);
                    toast.update(id, {
                        render: "Hive added successfully!", type: "success", isLoading: false, autoClose: 2000,
                    });
                }
            });

        };

        const [show, setShow] = useState(false);

        const handleClose = () => setShow(false);
        const handleShow = () => setShow(true);

        return (
            <>
                Add Hive <IconButton aria-label="add hive" color="secondary" onClick={handleShow}>
                    <AddBoxIcon />
                </IconButton>

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create new Hive</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2}>

                                <input type="text" name="name" placeholder="Name" />
                                <input type="text" name="description" placeholder="Description" />
                                { /* <label htmlFor="hive-image">Choose hive photo: <input type="file" id="hive-image" name="image" accept="image/*" placeholder="" onChange={onFileChange} /></label> */}

                                <input type="text" name="id_apiary" value={idApiary} hidden />
                                <Button type="submit" variant="contained" className="btn btn-primary btn-block">CREATE HIVE</Button>
                            </Stack>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

}