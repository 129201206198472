import { Container, Table } from 'react-bootstrap';
import { ReactSession } from 'react-client-session';
import CardDevice from './CardDevice';



export default function DevicesList({currentHive, devices, setOccupedDevices, setDevices, setCurrentDevice, currentDevice, setHiveDevices, setBeeFilesElab, setDates }) {
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("myuser");

  return (
    <Table bordered hover variant="light">
      <thead>
        <tr>
          {/* <th>#</th>
           <th>MAC</th>
          <th>IMEI</th> */}
          <th>#ID</th>
          <th>Name</th>
          {user.type_user === 0 ? (<Container hidden />) :
            (<th>Unlink</th>)}
        </tr>
      </thead>
      <tbody>
        {devices.map((d) => (
          <CardDevice currentHive={currentHive} key={d.id_device} device={d} setOccupedDevices={setOccupedDevices} setDevices={setDevices} setCurrentDevice={setCurrentDevice} currentDevice={currentDevice} setHiveDevices={setHiveDevices} setBeeFilesElab={setBeeFilesElab} setDates={setDates} />
        ))}
      </tbody>
    </Table>
  )
}