import { Grid } from '@mui/material';
import axios from 'axios';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';
import { ReactSession } from 'react-client-session';
import CardHive from './CardHive';
import 'react-toastify/dist/ReactToastify.css';

let currentIdH = 0;


export default function HiveList(props) {
    ReactSession.setStoreType("localStorage");
    const user = ReactSession.get("myuser");

    const changeHive = (h) => {
        if (h.id_hive !== currentIdH) {
            currentIdH = h.id_hive;
            props.setIsLoading(true);
            props.setBeeFilesElab([]);

            const id = toast.loading(`Loading data from ${h.name}...`);

            props.setCurrentHive(h);
            props.setCurrentDevice([]);

            axios.post(`${process.env.REACT_APP_URLDB}/listdeviceslinked`, { 'id_hive': h.id_hive, 'date_now': dateFormat(new Date(), "yyyy/mm/dd") }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response.data.error.code === 1) {
                    console.log("Errore");
                }
                else {
                    props.setDevices(response.data.payload);
                    const beeDev = response.data.payload.filter(d => parseInt(d.type, 10) === 2);
                    // if (beeDev.length > 0) {

                    //     axios.post(`${process.env.REACT_APP_URLDB}/beefilesavailable`, { 'id_device': beeDev[0] }, {
                    //         headers: {
                    //             'Authorization': `Bearer ${user.token}`
                    //         }
                    //     }).then((response) => {
                    //         if (response.data.error.code === 1) {
                    //             console.log('error');
                    //         }

                    //         let dateFiles = [];
                    //         let date = "";
                    //         let timeStart = "";
                    //         let timeEnd = "";

                    //         response.data.payload.map(d => {
                    //             if (date === "" || dateFormat(d.date_start, 'mm/dd/yyyy') > date || dateFormat(d.date_start, 'HH:MM') !== timeEnd) {
                    //                 if (date !== "") {
                    //                     dateFiles = [...dateFiles, { 'date': date, 'time_start': timeStart, 'time_end': timeEnd }];
                    //                 }
                    //                 date = dateFormat(d.date_start, 'mm/dd/yyyy');
                    //                 timeStart = dateFormat(d.date_start, 'HH:MM');
                    //             }

                    //             timeEnd = dateFormat(d.date_end, 'HH:MM');
                    //             return true;

                    //         });

                    //         dateFiles = [...dateFiles, { 'date': date, 'time_start': timeStart, 'time_end': timeEnd }];
                    //         // console.log(dateFiles);

                    //         props.setBeeFilesElab(dateFiles);
                    //         props.setDates(new Set(dateFiles.map(df => df.date)));

                    //     }).catch(e => toast.update(id, { render: "Error with connection!", type: "error", isLoading: false, autoClose: 2000 }));

                    // }
                }

            });



            axios.post(`${process.env.REACT_APP_URLDB}/listintervento`, { 'id_hive': h.id_hive }, {
                headers: {
                    'Authorization': `Bearer ${user.token}`
                }
            }).then((response) => {
                if (response.data.error.code === 1) {
                    toast.update(id, { render: "Error!", type: "error", isLoading: false });
                }

                props.setInterventi(response.data.payload);

                toast.update(id, {
                    render: "Done!", type: "success", isLoading: false, autoClose: 2000,
                });
                props.setIsLoading(false);


            }).catch(e => toast.update(id, { render: "Error with connection!", type: "error", isLoading: false, autoClose: 2000 }));
        }
    }





    return (
        props.hives.map(h => {
            return (
                <Grid key={h.id_hive} item xs={12} sm={6} md={3} mr={10} onClick={() => { changeHive(h) }}>
                    <CardHive hive={h} setHives={props.setHives} active={props.currentHive.id_hive === h.id_hive} />
                </Grid>
            )
        })

    );

}