import { Grid } from "@mui/material";
import dateFormat from "dateformat";
import { Container } from "react-bootstrap";
import { AppHumidityPercentage } from "../sections/@dashboard/app";


export default function GraphHumidity({ details }) {

    return (
        details.length !== 0 ?
            <Grid item xs={12} md={6} lg={8}>
                <AppHumidityPercentage
                    title="Humidity Graph"
                    chartLabels={details.map((det) =>
                        dateFormat(new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit' }).format(new Date(det.date.slice(0,19)), "mm/dd/yyyy"), "mm/dd/yyyy"))
                    }
                    chartData={[
                        {
                            name: 'envHumid',
                            type: 'line',
                            fill: 'solid',
                            data: details.map((det) => det.ehumid),
                        },
                    ]}
                />
            </Grid> : <Container />



    );

}