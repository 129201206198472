import PropTypes from 'prop-types';
import { useState } from 'react';
import axios from 'axios';
import dateFormat from 'dateformat';
import { toast } from 'react-toastify';

// @mui
import { Card, Stack, Divider, IconButton, CardHeader, Typography, Button, Slider, TextField, Switch } from '@mui/material';
import { Delete } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// components
import { ReactSession } from 'react-client-session';

import DatePicker from "react-datepicker";
import { Col, Container, Form, ListGroup, Modal, Row } from 'react-bootstrap';
import Iconify from '../../../components/Iconify';
import MenuPopover from '../../../components/MenuPopover';

import "react-datepicker/dist/react-datepicker.css";

// ----------------------------------------------------------------------
ReactSession.setStoreType("localStorage");
const user = ReactSession.get("myuser");

AppTasks.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  list: PropTypes.array.isRequired,
};

export default function AppTasks({ title, subheader, list, setList, currentHive, category, subcategory, ...other }) {

  return (
    <Card {...other}>
      <Stack
        direction="row"
        sx={{
          mt: 10,
          px: 2,
          py: 0.75,
          alignItems: 'center',
        }}
      >
        <CardHeader title={title} subheader={subheader} />
        <AddIntervento idHive={currentHive.id_hive} setList={setList} category={category} subcategory={subcategory} />
      </Stack>


      <Container>
        {list.map((task) => (
          <Row style={{ marginBottom: 15 }} key={task.id_intervento}>
            <TaskItem key={task.id_intervento} task={task} list={list} setList={setList} category={category} subcategory={subcategory} />
          </Row>
        ))
        }
      </Container>

    </Card>
  );
}

// ----------------------------------------------------------------------

TaskItem.propTypes = {
  task: PropTypes.object,
};

function TaskItem({ task, list, setList, category, subcategory }) {

  const [openDetail, setOpenDetail] = useState(false);


  const deleteIntervento = () => {
    if (window.confirm('Are you sure to delete this note?')) {
      const id = toast.loading(`Deleting Note...`);
      axios.post(`${process.env.REACT_APP_URLDB}/deleteintervento`, { 'id_intervento': task.id_intervento }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      }).then((response) => {
        if (response.data.error.code === 1) {
          console.log("Errore");
        }
        else {
          setList(oldArray => oldArray.filter((i) => i.id_intervento !== task.id_intervento));
          toast.update(id, {
            render: "Deleted!", type: "success", isLoading: false, autoClose: 2000,
          });
        }

      }).catch(e => toast.update(id, { render: `Error with connection! ${e}`, type: "error", isLoading: false, autoClose: 2000 }));

    }

  };

  const openDetailsIntervento = () => {
    setOpenDetail(!openDetail);
  }

  const closeDetailsIntervento = () => {
    setOpenDetail(!openDetail);
  }

  return (
    <>
      <Col>
        # {task.id_intervento}
      </Col>
      <Col>
        <Typography variant="h5">
          {category.filter(cat => cat.id_category === parseInt(task.id_category, 10)).map(cat => cat.name)}
        </Typography>
        <Typography sx={{ fontSize: 14 }} color="text.secondary">
          {subcategory.filter(sub => sub.id_subcategory === parseInt(task.id_subcategory, 10)).map(sub => sub.name)}
        </Typography>
      </Col>


      <Col>
        Date start: {dateFormat(task.date_start, "dd/mm/yyyy")}
      </Col>


      <Col>
        {openDetail ?
          <IconButton aria-label="open detail" color="secondary" onClick={openDetailsIntervento}>
            <KeyboardArrowUpIcon />
          </IconButton> :
          <IconButton aria-label="close detail" color="secondary" onClick={closeDetailsIntervento}>
            <KeyboardArrowDownIcon />
          </IconButton>
        }
      </Col>

      <Col>          {user.type_user === 0 ? (<Container />) :
        (
          <EditIntervento intervento={task} list={list} setList={setList} category={category} subcategory={subcategory} />
        )}
      </Col>


      <Col>
        {user.type_user === 0 ? (<Container />) :
          (
            <IconButton aria-label="delete" className='btn-send' onClick={deleteIntervento} sx={{ mb: 1 }}>
              <Delete />
            </IconButton>
          )}
      </Col>
      <Row>
        {openDetail &&
          <DetailIntervento intervento={task} />}
      </Row>
      <Row>
        <Divider color="black" />
      </Row>




    </>

  );



}

// ----------------------------------------------------------------------

MoreMenuButton.propTypes = {
  actions: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.object,
};

function MoreMenuButton({ actions, open, onOpen, onClose }) {
  return (
    <>
      <IconButton size="large" color="inherit" sx={{ opacity: 0.48 }} onClick={onOpen}>
        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={onClose}
        arrow="right-top"
        sx={{
          mt: -0.5,
          width: 'auto',
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 },
          },
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}




function AddIntervento({ idHive, setList, category, subcategory }) {

  const initElements = [-1, -1, -1, -1, -1, -1];
  const [elements, setElements] = useState(initElements); // beeframe, peste, varroa, flowering, stocks, endDate

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setElements(initElements);
  }
  const handleShow = () => setShow(true);

  const [queenBorn, setQueenBorn] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [currCat, setCurrCat] = useState(-1);
  const [currSub, setCurrSub] = useState(-1);
  /*
  const [currSubcategory, setCurrSubcategory] = useState(subcategory.filter(sub => sub.id_category === parseInt(category[0].id_category, 10)));
*/



  const handleSubmit = (event) => {
    event.preventDefault();

    const newIntervento = {
      id_hive: event.target.elements.id_hive.value,
      id_category: currCat,
      id_subcategory: currSub,
      bframe: elements[0] === -1 ? null : event.target.elements.bframe.value,
      peste: elements[1] === -1 ? null : event.target.elements.peste.value,
      varroa: elements[2] === -1 ? null : event.target.elements.varroa.value,
      honey_flow: elements[3] === -1 ? 0 : 1,
      queen_born: parseInt(currSub, 10) === 6 ? dateFormat(queenBorn, "yyyy/mm/dd") : "1900-01-01",
      date_start: dateFormat(startDate, "yyyy/mm/dd"),
      date_end: elements[5] === -1 ? "1900-01-01 00:00:00" : dateFormat(endDate, "yyyy/mm/dd"),
      notes: event.target.elements.notes.value,
      flowering_notes: event.target.elements.flowering_notes.value,
      honey_storage_notes: event.target.elements.honey_storage === undefined ? "" : event.target.elements.honey_storage.value,

      // value: parseInt(currSub, 10) === 6 ? 0 : event.target.elements.value.value,
    };


    axios.post(`${process.env.REACT_APP_URLDB}/addintervento`, newIntervento, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    }).then((response) => {
      if (response.data.error.code === 1) {
        console.log("error");
      }
      else {
        response.data.payload.category = category.filter(cat => cat.id_category === parseInt(response.data.payload.id_category, 10)).map(cat => cat.name)[0];
        response.data.payload.subcategory = subcategory.filter(sub => sub.id_subcategory === parseInt(response.data.payload.id_subcategory, 10)).map(sub => sub.name)[0];
        setList(oldArray => [...oldArray, response.data.payload]);
        // setCurrCat(category[0].id_category);
        setCurrSub(-1);
        handleClose();
      }
    });

  };


  function changedCategory(cat) {
    const selectedIndex = cat.target.options.selectedIndex;
    setCurrCat(cat.target.options[selectedIndex].getAttribute('data-key'));
    document.getElementsByName('subcategory')[0].selectedIndex = 0;
    setCurrSub(-1);
  }


  function changedSubcategory(sub) {
    const selectedIndex = sub.target.options.selectedIndex;
    setCurrSub(sub.target.options[selectedIndex].getAttribute('data-key'));
  }


  function changeSliderEnable(i) {
    setElements(oldArray => oldArray.map((el, index) => index === i ? el * -1 : el));
  }

  return (
    <>
      <Button variant="outlined" sx={{ mt: 3 }} className='btn-send-outlined' onClick={handleShow}>Add</Button>

      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w" size="xl" centered style={{ position: 'fixed', zIndex: '10000' }}>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <span>
              <Row>
                <Col xs={4}>
                  <label htmlFor="category">Category:
                    <Form.Select name="category" aria-label="Category select" defaultValue={"default"} onChange={e => changedCategory(e)} options={category}>
                      <option value={"default"} disabled>Choose an option</option>
                      {category.map((cat, index) => <option key={index} data-key={cat.id_category}>{cat.name}</option>)}
                    </Form.Select>
                  </label>
                </Col>
                <Col xs={4}>
                  <label htmlFor="subcategory">Subcategory:
                    <Form.Select name="subcategory" aria-label="Subcategory select" defaultValue={"default"} onChange={e => changedSubcategory(e)} options={subcategory.filter(sub => sub.id_category === parseInt(currCat, 10))} >
                      <option value={"default"} disabled>Choose an option</option>
                      {subcategory.filter(sub => sub.id_category === parseInt(currCat, 10)).map((sub, index) => <option key={index} data-key={sub.id_subcategory}>{sub.name}</option>)}
                    </Form.Select>
                  </label>
                </Col>
                {parseInt(currSub, 10) === 6 ? (<Col xs={4}>Queen Bee born: <DatePicker selected={queenBorn} onChange={(date: Date) => setQueenBorn(date)} /></Col>) : ""}
              </Row>
            </span>
            <br />
            <br />


            {parseInt(currSub, 10) !== 6 && parseInt(currSub, 10) !== 2 && parseInt(currSub, 10) !== 3 ?
              (<><p>
                Number of bees frame: <Switch onChange={() => changeSliderEnable(0)} />
                <Slider key={`slider-bframe`} name="bframe" id="bframe" disabled={elements[0] === -1}
                  aria-label="bee frame"
                  defaultValue={0}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={10} />
              </p><h4 style={{ marginLeft: 10, marginBottom: 10, }}>How many : </h4>
                <Row>
                  <Col>
                    Peste: <Switch onChange={() => changeSliderEnable(1)} />
                    <Slider key={`slider-Peste`} name="peste" id="peste" disabled={elements[1] === -1}
                      aria-label="peste"
                      defaultValue={0}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={5} />
                  </Col>
                  <Col>
                    Varroa: <Switch onChange={() => changeSliderEnable(2)} />
                    <Slider key={`slider-Varroa`} name="varroa" id="varroa" disabled={elements[2] === -1}
                      aria-label="varroa"
                      defaultValue={0}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={5} />
                  </Col>
                  <Row>
                    <div style={{ border: '1px solid rgba(0, 0, 0, 0.05)', padding: 5 }}>

                      <Row>
                        <Col>
                          <p>Flowering: </p>
                        </Col>
                        <Col>
                          Honey flow?<Switch onChange={() => changeSliderEnable(3)} />
                        </Col>
                      </Row>
                      <Col>
                        <TextField
                          id="flowering_notes"
                          name="flowering_notes"
                          label="Flowering notes"
                          multiline
                          rows={4}
                          variant="outlined"
                          sx={{ width: '100%' }}
                        />
                      </Col>
                    </div>
                  </Row>


                  {/* <Slider key={`slider-flowering`} name="flowering" id="flowering" disabled={elements[3] === -1}
                      aria-label="flowering"
                      defaultValue={0}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={5} /> */}

                  <Row>
                    <div style={{ border: '1px solid rgba(0, 0, 0, 0.05)', padding: 5, marginTop: 10 }}>

                      <Row>
                        <Col>
                          Honey storage: <Switch onChange={() => changeSliderEnable(4)} />
                        </Col>
                      </Row>
                      {elements[4] !== -1 && <Col>
                        <TextField
                          id="honey_storage"
                          name="honey_storage"
                          label="Honey storage notes"
                          multiline
                          rows={4}
                          variant="outlined"
                          sx={{ width: '100%' }}
                        />
                      </Col>}
                    </div>
                  </Row>
                </Row>
              </>
              ) : ""}

            <br />

            <Row>
              <Col>
                Start date note <DatePicker selected={startDate} selectsStart startDate={startDate} onChange={(date: Date) => setStartDate(date)} />
              </Col>
              <Col>
                End date note <Switch onChange={() => changeSliderEnable(5)} /> {elements[5] !== -1 ? (<DatePicker selected={endDate} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} onChange={(date: Date) => setEndDate(date)} />) : ("")}
              </Col>
            </Row>

            <br />
            <Row>
              <TextField
                id="notes"
                name="notes"
                label="Annotation"
                multiline
                fullWidth
                margin='dense'
                rows={6}
                variant="outlined"
                sx={{ width: '100%' }}
              />
              <Col>
                {/* {parseInt(currSub, 10) !== 6 ?

                  (
                    <TextField
                      type="number"
                      name="value"
                      label="Value"
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: '25ch', mt: 8 }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">mg</InputAdornment>,
                      }}
                    />
                  ) : ""} */}
              </Col>
            </Row>
            <input type="text" name="id_hive" defaultValue={idHive} hidden />
            <Divider sx={{ mb: 4, mt: 4 }} />
            {parseInt(currSub, 10) !== -1 && parseInt(currCat, 10) !== -1 ?
              (<Button type="submit" variant="contained" className='btn-send' fullWidth>CREATE</Button>)
              :
              (<Button type="submit" variant="contained" className='btn-send' fullWidth disabled>CREATE</Button>)
            }
          </form>
        </Modal.Body>

      </Modal>
    </>
  );
}



function EditIntervento({ intervento, list, setList, category, subcategory }) {

  const currCat = intervento.id_category;
  const currSub = intervento.id_subcategory;

  const initElements = [
    intervento.bframe === null ? -1 : 1,
    intervento.peste === null ? -1 : 1,
    intervento.varroa === null ? -1 : 1,
    intervento.honey_flow === null ? -1 : 1,
    intervento.honey_storage_notes === null || intervento.honey_storage_notes === "" ? -1 : 1,
    intervento.date_end === "1900-01-01 00:00:00" ? -1 : 1,
    intervento.queen_born === "1900-01-01" ? -1 : 1,
  ];


  const [elements, setElements] = useState(initElements); // beeframe, peste, varroa, flowering, stocks, endDate


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  }
  const [queenBorn, setQueenBorn] = useState(new Date(intervento.queen_born));
  const [startDate, setStartDate] = useState(new Date(intervento.date_start));
  const [endDate, setEndDate] = useState(intervento.date_end === "1900-01-01 00:00:00" ? new Date() : new Date(intervento.date_end));





  const handleSubmit = (event) => {
    event.preventDefault();

    const EditIntervento = {
      id_intervento: event.target.elements.id_intervento.value,
      id_category: currCat,
      id_subcategory: currSub,
      bframe: elements[0] === -1 ? null : event.target.elements.bframe.value,
      peste: elements[1] === -1 ? null : event.target.elements.peste.value,
      varroa: elements[2] === -1 ? null : event.target.elements.varroa.value,
      honey_flow: elements[3] === -1 ? 0 : 1,
      queen_born: parseInt(currSub, 10) === 6 ? dateFormat(queenBorn, "yyyy/mm/dd") : "1900-01-01",
      date_start: dateFormat(startDate, "yyyy/mm/dd"),
      date_end: elements[5] === -1 ? "1900-01-01 00:00:00" : dateFormat(endDate, "yyyy/mm/dd"),
      notes: event.target.elements.notes.value,
      flowering_notes: elements[4] === -1 ? null : event.target.elements.flowering_notes.value,
      honey_storage_notes: event.target.elements.honey_storage === undefined ? "" : event.target.elements.honey_storage.value,
    };


    axios.post(`${process.env.REACT_APP_URLDB}/editintervento`, EditIntervento, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    }).then((response) => {
      if (response.data.error.code === 1) {
        console.log("error");
      }
      else {

        const newList = list.map(el => (el.id_intervento.toString() === EditIntervento.id_intervento.toString() ? EditIntervento : el));
        setList(newList);
        handleClose();
      }
    });

  };

  /*
    function changedCategory(cat) {
      const selectedIndex = cat.target.options.selectedIndex;
      setCurrCat(cat.target.options[selectedIndex].getAttribute('data-key'));
      setCurrSubcategory(subcategory.filter(sub => sub.id_category === parseInt(cat.target.options[selectedIndex].getAttribute('data-key'), 10)));
    }


      function changedSubcategory(sub) {
      const selectedIndex = sub.target.options.selectedIndex;
      setCurrSub(sub.target.options[selectedIndex].getAttribute('data-key'));
    }
      */

  function changeSliderEnable(i) {
    setElements(oldArray => oldArray.map((el, index) => (index === i ? el * -1 : el)));
  }



  return (
    <>
      <Button variant="outlined" className="btn-edit-outlined" onClick={handleShow}>Edit</Button>

      <Modal show={show} onHide={handleClose} size="xl" centered style={{ position: 'fixed', zIndex: '10000' }}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Note n.{intervento.id_intervento}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <span>
              <Row>
                <Col xs={4}>
                  <label htmlFor="category">Category:
                    <Form.Select disabled name="category" aria-label="Category select" options={category} defaultValue={intervento.id_category}>
                      <option key={currCat} value={currCat} data-key={currCat}>{category[intervento.id_category - 1].name}</option>
                    </Form.Select>
                  </label>

                </Col>
                <Col xs={4}>
                  <label htmlFor="subcategory">Subcategory:
                    <Form.Select disabled name="subcategory" aria-label="Subcategory select" options={subcategory} defaultValue={intervento.id_subcategory}>
                      <option key={currSub} value={currSub} data-key={currSub}>{subcategory[intervento.id_subcategory - 1].name}</option>
                    </Form.Select>
                  </label>
                </Col>
                {parseInt(currSub, 10) === 6 ? (<Col>Queen Bee born: <DatePicker selected={queenBorn} onChange={(date: Date) => setQueenBorn(date)} /></Col>) : ""}

              </Row>
            </span>
            <br />
            <br />

            {parseInt(currSub, 10) !== 6 && parseInt(currSub, 10) !== 2 && parseInt(currSub, 10) !== 3 ?
              (<>
                Number of bees frame: <Switch onChange={() => changeSliderEnable(0)} checked={elements[0] > 0} />
                <Slider key={`slideredit-bframe`} name="bframe" id="bframe" disabled={elements[0] === -1}
                  aria-label="bee frame"
                  defaultValue={intervento.bframe}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={10}
                />
                <h4>How many: </h4>
                <Row>
                  <Col>
                    Peste: <Switch onChange={() => changeSliderEnable(1)} checked={elements[1] > 0} />
                    <Slider key={`slideredit-Peste`} name="peste" id="peste" disabled={elements[1] === -1}
                      aria-label="peste"
                      defaultValue={intervento.peste}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={5}
                    />
                    Varroa: <Switch onChange={() => changeSliderEnable(2)} checked={elements[2] > 0} />
                    <Slider key={`slideredit-Varroa`} name="varroa" id="varroa" disabled={elements[2] === -1}
                      aria-label="varroa"
                      defaultValue={intervento.varroa}
                      valueLabelDisplay="auto"
                      step={1}
                      marks
                      min={0}
                      max={5}
                    />
                  </Col>
                  <Row>
                    <div style={{ border: '1px solid rgba(0, 0, 0, 0.05)', padding: 5 }}>

                      <Row>
                        <Col>
                          <p>Flowering: </p>
                        </Col>
                        <Col>
                          Honey flow?<Switch onChange={() => changeSliderEnable(3)} checked={elements[3] > 0} />
                        </Col>
                      </Row>
                      <Col>
                        <TextField
                          id="flowering_notes"
                          name="flowering_notes"
                          label="Flowering notes"
                          multiline
                          rows={4}
                          defaultValue={intervento.flowering_notes}
                          variant="outlined"
                          sx={{ width: '100%' }}
                        />
                      </Col>
                    </div>
                  </Row>
                  <Row>
                    <div style={{ border: '1px solid rgba(0, 0, 0, 0.05)', padding: 5, marginTop: 10 }}>

                      <Row>
                        <Col>
                          Honey storage: <Switch onChange={() => changeSliderEnable(4)} checked={elements[4] > 0} />
                        </Col>
                      </Row>
                      {elements[4] !== -1 && <Col>
                        <TextField
                          id="honey_storage_notes"
                          name="honey_storage"
                          label="Honey storage notes"
                          defaultValue={intervento.honey_storage_notes}
                          multiline
                          rows={4}
                          variant="outlined"
                          sx={{ width: '100%' }}
                        />
                      </Col>}
                    </div>
                  </Row>

                </Row></>
              ) : ""}

            <Row>
              <Col>
                Start date note <DatePicker selected={startDate} selectsStart startDate={startDate} onChange={(date: Date) => setStartDate(date)} />
              </Col>
              <Col>
                End date note <Switch onChange={() => changeSliderEnable(5)} checked={elements[5] > 0} /> {elements[5] !== -1 ? (<DatePicker selected={endDate} selectsEnd startDate={startDate} endDate={endDate} minDate={startDate} onChange={(date: Date) => setEndDate(date)} />) : ("")}
              </Col>
            </Row>


            <TextField
              id="notes"
              name="notes"
              label="Annotation"
              defaultValue={intervento.notes}
              multiline
              fullWidth
              margin='dense'
              rows={6}
            />
            <br />


            <input type="text" name="id_intervento" defaultValue={intervento.id_intervento} hidden />
            <Divider sx={{ mb: 4, mt: 4 }} />
            <Button type="submit" className="btn-edit" variant="outlined" fullWidth>EDIT</Button>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}


function DetailIntervento({ intervento }) {
  console.log(intervento.honey_flow)
  return (
    <ListGroup>
      <ListGroup.Item><b>{intervento.bframe ? (`| Bee frame:  ${intervento.bframe} `) : ("")}</b><b>{intervento.peste ? (`| Peste:  ${intervento.peste} `) : ("")}</b><b>{intervento.varroa ? (`| Varroa:  ${intervento.varroa} `) : ("")}</b></ListGroup.Item>
      <ListGroup.Item><b>Flowering : (Honey Flow? {intervento.honey_flow === 1 ? "YES" : "NO"})</b><pre>{intervento.flowering_notes}</pre></ListGroup.Item>
      {intervento.honey_storage === '0' ? "" : <ListGroup.Item><b>Honey storage : </b><pre>{intervento.honey_storage_notes}</pre></ListGroup.Item>}
      {intervento.queen_born !== "1900-01-01" ? (<ListGroup.Item><b>Queen born : </b>{dateFormat(intervento.queen_born, "dd/mm/yyyy")}</ListGroup.Item>) : ""}
      <ListGroup.Item><b>Start date note : </b>{dateFormat(intervento.date_start, "dd/mm/yyyy")}</ListGroup.Item>
      {intervento.date_end !== "1900-01-01 00:00:00" ? (<ListGroup.Item><b>End date note : </b>{dateFormat(intervento.date_end, "dd/mm/yyyy")}</ListGroup.Item>) : ("")}
      <ListGroup.Item><b>Annotation : </b><pre>{intervento.notes}</pre></ListGroup.Item>

    </ListGroup>

  );
}







