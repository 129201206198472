// @mui
import PropTypes from 'prop-types';
import { Card, IconButton, Typography } from '@mui/material';
import axios from 'axios';
import { ReactSession } from 'react-client-session';

// utils
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import {  useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll';

ReactSession.setStoreType("localStorage");

// ----------------------------------------------------------------------

CardArnia.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  sx: PropTypes.object,
};

export default function CardArnia({ hive, setHives, color = 'success', sx, ...other }) {
  const user = ReactSession.get("myuser");

  const [isEdit, setisEdit] = useState(false);
  const [name, setName] = useState(hive.name);
  const [subtitle, setDescription] = useState(hive.description);
  // const [hasWeightScale, setHasWeightScale] = useState(false);
  // const [hasBeeSense, setHasBeeSense] = useState(false);


  const editHive = () => {
    setisEdit(!isEdit);

    if (isEdit) {
      axios.post(`${process.env.REACT_APP_URLDB}/edithive`, { 'id_hive': hive.id_hive, 'name': name, 'description': subtitle }, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      }).then((response) => {
        if (response.data.error.code === 1) {
          console.log("errore");
        }

        hive.name = name;
        hive.description = subtitle;
        setHives(oldArray => oldArray.map(el =>
          (el.id_hive.toString() === hive.id_hive.toString() ? hive : el)
        ));
      });


    }
  }




  return (
    <AnchorLink href={isEdit ? '#' : '#hivedetail'} style={{ textDecoration: 'none' }}>
      <Card
        sx={{
          py: 5,
          boxShadow: 0,
          textAlign: 'center',
          color: (theme) => theme.palette[color].darker,
          bgcolor: (theme) => theme.palette[color].lighter,
          ...sx,
        }}
        {...other}
      >
        {/* <img src={`data:image/png;base64,${image}`} alt="hive" width={1}/> */}
        {user.type_user === 0 ? (<Container />) :
          (<IconButton aria-label="download csv" color="secondary" sx={{ alignItems: "center" }} onClick={editHive}>
            {isEdit ? <DoneIcon /> : <EditIcon />}
          </IconButton>)}



        <Typography variant="h3" sx={{ '&:hover': { textDecoration: 'underline' } }} contentEditable={isEdit ? "true" : "false"} suppressContentEditableWarning="true" onInput={(e) => setName(e.currentTarget.textContent)}>{hive.name}</Typography>
        {/* {hasWeightScale === true ? <Tooltip title="WeightScale Linked" arrow><ScaleIcon /></Tooltip> : <></>}
        {hasBeeSense === true ? <Tooltip title="BeeSense Linked" arrow><EmojiNatureIcon /></Tooltip> : <></>} */}

        <Row>
          {(isEdit && subtitle === "") ? <Col md="auto"><h5>Description: </h5></Col> : <Container />}
          <Col><Typography variant="subtitle2" sx={{ opacity: 0.72 }} contentEditable={isEdit ? "true" : "false"} suppressContentEditableWarning="true" onInput={(e) => setDescription(e.currentTarget.textContent)} >
            {hive.description}
          </Typography> </Col>
        </Row>
      </Card >
    </AnchorLink>
  );
}
