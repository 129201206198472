import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import Upload from './pages/Upload';
import Hives from './pages/Hives';
import Download from './pages/Download';
import PasswordForget from './pages/PasswordForget';
import PasswordRecovery from './pages/PasswordRecovery';
import Export2 from './pages/Export2';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'upload', element: <Upload /> },
        { path: 'products', element: <Products /> },
        { path: 'hives', element: <Hives /> },
        // { path: 'export', element: <Export /> },
        { path: 'export', element: <Export2 /> },

        { path: 'download', element: <Download /> },
        // { path: 'devices', element: <Devices /> },
      ],
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/app" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/password',
      element: <DashboardLayout />,
      children: [
        { path: 'forget', element: <PasswordForget /> },
        { path: 'recovery', element: <PasswordRecovery /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
