import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Drawer, Typography, IconButton, ListItem, List, Toolbar, AppBar, Button, Divider } from '@mui/material';
import { ReactSession } from 'react-client-session';
import LogoutIcon from '@mui/icons-material/Logout';
import { Add } from '@mui/icons-material';
// mock
import account from '../../_mock/account';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
//
import navConfig from './NavConfig';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

const TYPE_RES = {
  0: 'SUPER RESEARCHER',
  1: 'RESEARCHER',
};

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const navigate = useNavigate();
  ReactSession.setStoreType("localStorage");
  const user = window.user === undefined ? account : window.user;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  });

  const logout = () => {
    console.log("LOGOUT");
    ReactSession.remove("myuser");
    navigate('/login', { replace: true });
  }



  const drawer = (
    <><div>
      <List disablePadding>
        <ListItem disablePadding>
          <Box sx={{ px: 2.5, paddingTop: 3, display: 'inline-flex' }}>
            <Logo />
          </Box>
        </ListItem>
        <hr width="100%" size="8" align="center" />
        <ListItem disablePadding>
          <Box sx={{ mb: 5, mx: 2.5 }}>
            <AccountStyle>
              {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
              <Box sx={{ ml: 2 }}>
                <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                  {user.firstName} {user.lastName}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {TYPE_RES[user.type_user]}
                </Typography>
              </Box>
              <IconButton aria-label="logout" color="secondary" onClick={logout}>
                <LogoutIcon />
              </IconButton>
            </AccountStyle>
          </Box>
        </ListItem>
      </List>
      {/* </div><Button variant="outlined" startIcon={<Add sx={{ color: "#F1A41A" }} />} sx={{ ml:2, mr:2, mb: 5, justifyContent: 'stretch', color: 'black' }}>
        Upload .bee
      </Button><div> */}
      <NavSection navConfig={navConfig} />

    </div></>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${DRAWER_WIDTH}px)` },
          ml: { md: `${DRAWER_WIDTH}px` },
        }}
      >
        <Toolbar sx={{
          display: { md: 'none', display: 'flex' }, justifyContent: "space-between",
        }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            align="left"
            onClick={handleDrawerToggle}
            size="medium"
            sx={{ display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            PRIMA
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: DRAWER_WIDTH }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}>
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'inline' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
          open>
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
