/* import { faker } from '@faker-js/faker';


*/
import { Button, Modal, Stack } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { ReactSession } from 'react-client-session';
import axios from 'axios'
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Fab, Select, FormControl, InputLabel, MenuItem } from '@mui/material';
// components
import ApiaryList from '../components/ApiaryList';

import Page from '../components/Page';




// sections

// import {
//   AppNewsUpdate,
//   AppOrderTimeline,
//   AppCurrentVisits,
//   AppTemperatureDegrees,
//   AppTrafficBySite,
//   AppCurrentSubject,
//   AppConversionRates,
// } from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardApp() {
  const theme = useTheme();
  ReactSession.setStoreType("localStorage");
  const user = ReactSession.get("myuser");
  if (ReactSession.get("fixHive") === 1) {
    ReactSession.set("fixHive", 0);
    window.location.reload();
  }


  const [apiary, setApiary] = useState([]);
  const [groupedApiary, setGroupedApiary] = useState([]);
  const [totalApiaries, setTotalApiaries] = useState([]);

  //  console.log(user); // [DEBUG]

  if (typeof user === "undefined") {
    window.location.href = "/login";
  }

  function groupApiaryByUser(ap) {
    const res = Object.values(_.groupBy(ap, 'id_user'));
    return res;
  }

  const getAllApiaries = (event) => {
    const id = toast.loading(`Getting all apiaries...`);
    axios.post(`${process.env.REACT_APP_URLDB}/listglobalapiary`, {}, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    }).then((response) => {
      if (response.data.error.code === 1) {
        toast.update(id, { render: "Error!", type: "error", isLoading: false });
      }
      else {
        setTotalApiaries(response.data.payload);
        setApiary(response.data.payload);
        setGroupedApiary(groupApiaryByUser(response.data.payload));
        toast.update(id, {
          render: "Done!", type: "success", isLoading: false, autoClose: 2000,
        });
      }

    }).catch(e => toast.update(id, { render: "Error with connection!\n maybe you need to re-authenticate", type: "error", isLoading: false, autoClose: 2000 }));
  };

  const getUserApiaries = (event) => {
    const id = toast.loading(`Getting your apiaries...`);
    axios.post(`${process.env.REACT_APP_URLDB}/listapiary`, { 'id_user': user.id_user }, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    }).then((response) => {
      if (response.data.error.code === 1) {
        toast.update(id, { render: "Error!", type: "error", isLoading: false });
      }
      else {
        setApiary(response.data.payload);
        toast.update(id, {
          render: "Done!", type: "success", isLoading: false, autoClose: 2000,
        });
      }

    }).catch(e => toast.update(id, { render: "Error with connection!\n Try to re-authenticate.", type: "error", isLoading: false, autoClose: 2000 }));
  };

  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setselectedUser] = useState('');
  const handleselectedUser = (event) => {
    console.log(ApiaryList);
    setApiary(totalApiaries.filter((ap) => ap.id_user === event.target.value.id_user));
    setselectedUser(event.target.value);

  };


  useEffect(() => {

    if (user.type_user === 0) {
      // SUPERUSER
      getAllApiaries();

      axios.post(`${process.env.REACT_APP_URLDB}/getuserlist`, {}, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      }).then((response) => {
        if (response.data.error.code === 1) {
          console.log("error");
        }
        else {
          setUsersList(response.data.payload);
          setselectedUser("all");
        }
      });
    }
    else {
      // USER
      getUserApiaries();

    }
  }, []);

  function getUserById(id) {
    return Object.values(usersList.find(e => e.id_user === id).email);
  }


  const nome = user.firstName;

  return (
    <Page title="Dashboard">
      <ToastContainer />

      <Container maxWidth="xl" sx={{ ml: 10 }}>
        <div style={{ display: 'flex', justifyContent: 'right', marginRight: '18%' }}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Welcome back {nome}
          </Typography>
        </div>



        <Typography variant="h5" sx={{ mb: 5, color: "#606060" }}>
          {user.type_user === 0 ? "Global apiaries" : "Your apiaries:"}
        </Typography>

   

        {user.type_user === 0 ? (
          <FormControl sx={{ m: 1, minWidth: 200 }}>
            <InputLabel id="demo-simple-select-helper-label">User</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={selectedUser}
              label="User"
              onChange={e => handleselectedUser(e)}
            >
              <MenuItem value="all">All</MenuItem>
              {usersList?.map((usr) => {
                return (<MenuItem value={usr}>{usr.email}</MenuItem>)
              })}
            </Select>
          </FormControl>)
          :
          (
            <MyModal idUser={user.id_user} setApiary={setApiary} />
          )}
        {user.type_user === 0 && selectedUser === "all" ? (
          groupedApiary.map((e, i) => {
            return (
              <>
                <Typography variant="h5" sx={{ mb: 5 }}>{getUserById(e[0].id_user)}</Typography>
                <Grid container mt={10} mb={10}>
                  <ApiaryList apiaries={e}/>
                </Grid>
              </>
            )
          })
        )
          :
          (<Grid container mt={10} mb={10}>
            <ApiaryList apiaries={apiary} />
          </Grid>)}

      </Container>
    </Page >
  );

  function MyModal({ idUser, setApiary }) {

    const handleSubmit = (event) => {
      event.preventDefault();

      const apiary = {
        id_user: event.target.elements.id_user.value,
        name: event.target.elements.name.value
      };


      axios.post(`${process.env.REACT_APP_URLDB}/addapiary`, apiary, {
        headers: {
          'Authorization': `Bearer ${user.token}`
        }
      }).then((response) => {
        if (response.data.error.code === 1) {
          console.log("error");
        }
        else {
          console.log("added success");
          setApiary(oldArray => [...oldArray, response.data.payload]);

        }
      });

    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
      <>
        <Fab size="small" color="default" variant='extended' aria-label="add" onClick={handleShow}>
          Add apiary <AddIcon />
        </Fab>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create new Apiary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={handleSubmit}>
              <Stack spacing={3}>

                <input type="text" name="name" placeholder="Apiary name" />
                <input type="text" name="id_user" value={idUser} hidden />
                <br />
                <Button type="submit" variant="contained" className="btn btn-primary btn-block">CREATE APIARY</Button>

              </Stack>

            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }


}

