import axios from 'axios';
import { ReactSession } from 'react-client-session';


// ----------------------------------------------------------------------

export async function getAws() {
  try {
    const user = ReactSession.get("myuser");
    const response = await axios.post(`${process.env.REACT_APP_URLDB}/getAWS`, null, {
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    
    return response.data;
  } catch (error) {
    console.log(error);
    throw error; // rilanciamo l'errore per gestirlo dove la funzione viene chiamata
  }
}
