import { AppTasks } from "../sections/@dashboard/app";


export default function InterventoList(props) {

return(
  <AppTasks
      title="Note"
      list={props.interventi}
      setList = {props.setInterventi}
      currentHive={props.hive}
      category={props.category} 
      subcategory={props.subcategory}
    />
);

}
