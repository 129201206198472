import React, { Component} from 'react';


// ----------------------------------------------------------------------

export default function Upload() {

  Component.state = { 
  
    // Initially, no file is selected 
    selectedFile: null
  }; 

  Component.onFileChange = event => { 
    // Update the state 
    Component.setState({ selectedFile: event.target.files[0] }); 
  }; 


  const handleSubmission = () => {
		const formData = new FormData();

		formData.append('File', Component.state.selectedFile);

		fetch(
			'https://freeimage.host/api/1/upload?key=<YOUR_API_KEY>',
			{
				method: 'POST',
				body: formData,
			}
		)
			.then((response) => response.json())
			.then((result) => {
				console.log('Success:', result);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

  return ( 
    <div> 
        <h1> 
          Carica il file .bee 
        </h1> 
        <br/>
        <div> 
            <input type="file" onChange={React.onFileChange} /> 
            <button onClick={handleSubmission}> 
              Upload! 
            </button> 
        </div> 
    </div> 
  );

}
