import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import reactLogo from '../assets/images/logo.png'


// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  const logo = <Box component="img" src={reactLogo} sx={{ width: 80, height: 60, ...sx }} />

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <><RouterLink to="/" style={{ textDecoration: 'none', color: 'inherit' }}><div style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    {logo}
    <h5 color='black'>
      PRIMA
    </h5>
  </div>
  </RouterLink>
  </>;
}
